import { useStaticQuery, graphql } from "gatsby"

export const useMetaCollectionStores = () => {
  return useStaticQuery(graphql`
    query MetaCollectionStores {
      stores: markdownRemark(
        fields: {
          contentKind: { eq: "meta" }
          slug: { eq: "collection/stores" }
        }
      ) {
        html
        plain: excerpt(format: PLAIN, truncate: false)
      }
    }
  `).stores
}
