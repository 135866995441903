import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticleCollection from "../components/article-collection"
import { useMetaCollectionStores } from "../meta/collection/stores"

const StoresPage = () => {
  const description = useMetaCollectionStores().plain

  return (
    <Layout>
      <SEO title="Stores" description={description} />

      <StaticQuery
        query={graphql`
          query Stores {
            stores: allMarkdownRemark(
              filter: { fields: { contentKind: { eq: "store" } } }
              sort: { fields: frontmatter___title, order: ASC }
            ) {
              ...BaseArticleCollectionFragment
              nodes {
                frontmatter {
                  imageCover: image {
                    ... on File {
                      childImageSharp {
                        fluid(
                          maxWidth: 400
                          maxHeight: 300
                          background: "rgba(255, 255, 255, 1)"
                          fit: COVER
                          cropFocus: ATTENTION
                        ) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                  imageContain: image {
                    ... on File {
                      childImageSharp {
                        fluid(
                          maxWidth: 400
                          maxHeight: 300
                          background: "rgba(255, 255, 255, 1)"
                          fit: CONTAIN
                        ) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <ArticleCollection
            imageVariant="title"
            articleTypePath="/stores"
            articles={data.stores}
          />
        )}
      />
    </Layout>
  )
}

export default StoresPage
